<template>
	<div class="wrapper mypage">

		<slot></slot>
		

		<div class="loading-page">
			<p>ローディング</p>
		</div>

		<!-- <div class="age-verification-page" :class="{'is-open': login !== true && !isAgeVerified && !isAgeVerifiedTemp}">
				<div class="title">
					<p>年齢確認</p>
				</div>
				<div class="text">
					<p>このサイトはお酒に関する情報が含まれています。</p>
					<p>20歳未満の方はご覧いただけません。</p>
				</div>
				<div class="question">
					<p>あなたは20歳以上ですか？</p>
				</div>
				<div class="btn-box">
					<div class="btn" id="r20-test" @click="clickAgeVerified">はい</div>
					<div class="btn gray" @click="clickAgeNotVerified">いいえ</div>
				</div>
				<div class="next-check">
					<label>
						<input type="checkbox" class="checkbox" v-model="isSaveAgeVerified">
						<div class="label-mark"></div>
						<div class="label-text">次回よりチェックを省略</div>
					</label>
					<div class="note">
						<p>※未成年の方と端末を共同利用している場合はチェックを入れないでください</p>
					</div>
				</div>
			</div>
		</div> -->

		<div class="mypage-footer">
			<div class="footer-menu">
				<div class="left">
					<div class="name">
						<NuxtImg src="/img/Shakeme_logo_neon.svg" />
					</div>
					<div class="text">
						<p>
							<span class="sub">運営会社</span>
							<span>株式会社PATURN</span>
						</p>
						<p>
							<span>〒162-0843</span>
							<span>東京都新宿区市谷田町3-8市ヶ谷科学技術イノベーションセンタービル2F</span>
						</p>
						<p>
							<span class="sub">お問い合わせ先</span>
							<a href="mailto:contact@shakeme.jp">contact@shakeme.jp</a>
						</p>
						<p>
							<small>※ご返信は通常3営業日以内にさせていただきます。</small>
						</p>
					</div>
				</div>
				<div class="right">
					<ul>
						<li><a href="https://paturn.co.jp/privacypolicy" target="_blank" rel="noopener">プライバシーポリシー</a></li>
						<li><a href="/document/scta" target="_blank" rel="noopener">特定商取引法に基づく表記</a></li>
						<li><a href="/document/termsofuse" target="_blank" rel="noopener">利用規約</a></li>
						<li><a href="/event/hayashi/c75d808f-b09e-4638-83aa-a9ae88173f61/#faq" target="_blank" rel="noopener">よくある質問</a></li>
						<li><a href="/document/howtouse" target="_blank" rel="noopener">使い方</a></li>
					</ul>
				</div>
			</div>
			<div class="copyright">copyright &copy; 2024 PATURN Ltd. All Rights Reserved.</div>
		</div>
	</div>
</template>

<script setup>
	const login = useState('login');

	let isMenuOpen = ref(false)	// メニューの開閉
	
	const clickMenu = () => {
		isMenuOpen.value = !isMenuOpen.value
	}

	const ageVerified = useAgeVerified();
	const { saveAgeVerified } = ageVerified;
	const { isAgeVerified } = storeToRefs(ageVerified);

	let isAgeVerifiedTemp = ref(false)	// 年齢確認チェック(一時)
	let isSaveAgeVerified = ref(false)	// 年齢確認チェックを保存するかチェック
	
	const clickAgeVerified = () => {
		if(isSaveAgeVerified.value){
			saveAgeVerified();
		}else{
			isAgeVerifiedTemp.value = true
		}
	}
	
	const clickAgeNotVerified = () => {
		const url = 'https://www.google.com/'
		window.location.href = url
	}

	useHead({
		bodyAttrs: {
			class: 'mypage'
		}
	})
</script>